import React, { useRef, Component, useState } from "react";
import { styled } from '@mui/material/styles';
//import {hot} from "react-hot-loader";
import "./Portfolio.css";


import Thumbnail from "./Thumbnail";

//Assets
import Pilot from "../images/Pilot/pilot-thumbnail.png";
import Sonic from "../images/SonicLink/sonic-thumbnail.png";
import LastMile from "../images/LastMileDelivery/thumbnail1.png";

function Portfolio() {

    return(
        <div className="portfolio-container">
            <Thumbnail img={Pilot} url="/atlassian-pilot" name="Pilot"/>
            <Thumbnail img={Sonic} url="/sonic-link" name="Sonic Link"/>
            <Thumbnail img={LastMile} url="/last-mile-delivery" name="Last Mile Delivery"/>
        </div>
    )
}

export default Portfolio;