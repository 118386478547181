import React, { Component, useState } from "react";
//import {hot} from "react-hot-loader";
import "./SonicLink.css";


//Components
import MenuBar from "../MenuBar";
import DesignProcessStep from "../components/DesignProcessStep";
import UserProfile from "../components/UserProfile";

//Assets
import Site from "../images/SonicLink/site.png";
import Mood1 from "../images/SonicLink/mood1.png";
import Mood2 from "../images/SonicLink/mood2.png";
import Mood3 from "../images/SonicLink/mood3.png";
import Mood4 from "../images/SonicLink/mood4.png";
import Mood5 from "../images/SonicLink/mood5.png";
import MVP from "../images/SonicLink/MVP.png";
import Lofi1 from "../images/SonicLink/lofi1.png";
import Lofi2 from "../images/SonicLink/lofi2.png";
import Lofi3 from "../images/SonicLink/lofi3.png";
import Lofi4 from "../images/SonicLink/lofi4.png";
import Archi from "../images/SonicLink/architecture.png";
import Hifi1 from "../images/SonicLink/hifi1.png";
import Hifi2 from "../images/SonicLink/hifi2.png";
import Hifi3 from "../images/SonicLink/hifi3.png";
import Hifi4 from "../images/SonicLink/hifi4.png";
import Proto1 from "../images/SonicLink/proto1.png";
import Proto2 from "../images/SonicLink/proto2.png";


class SonicLink extends Component{
    render(){
      return(
          <div className="home">
            <MenuBar/>

            <section className="section" id="sonicLink">
              <div className="overlayCharcoal">lalala</div>
              <div className="section-content">
                <div className="herosection">
                    <h1>
                    Sonic Link - Design for Public Interactive Installation
                    </h1>
                    <b2>
                    MAP Mima, an arts platform in Lake Macquarie, challenged USYD students to design and develop an application to interact with the catenary lights in its outdoors space.                    </b2>
                </div>
              </div>
            </section>

            <section className="section" id="design-process-2">
              <div className="section-content">
                <h3>Design Process</h3>
                <div className="design-process-timeline">
                    <DesignProcessStep name="Research" step="1" methods={["trends & culture", "site analysis", "user studies & theme"]}/>
                    <DesignProcessStep name="Concept" step="2" methods={["brainstorming", "redefine brief", "moodboard"]}/>
                    <DesignProcessStep name="Design" step="3" methods={["mvp", "wireframess", "preliminary prototype"]}/>
                    <DesignProcessStep name="Evaluation" step="4" methods={["questionnaires", "interviews", "think aloud"]}/>
                    <DesignProcessStep name="Justification" step="5" methods={["technical aspects", "interface design", "working prototype"]}/>
                </div>
              </div>
            </section>

            <section className="section" id="research">
              <div className="section-content">
                <h3>Research</h3>
                <div className="sub-section">
                  <b2 className="sub-section-title">
                    BACKGROUND & TRENDS
                  </b2>
                  <b3 className="two-column">
                  On the traditional land of the Awabakal people, Speers Point’s Multi-Arts Pavillion (MAP) Mima is a multi-arts platform focused on providing an innovative, high-tech space that pushes the boundaries of how people view and interact with contemporary art, its location and audience. <br></br><br></br>

                  With MAP Mima's identity in mind, we seek to take the following two principles as part of the focus when coming up with a design proposal for MAP Mima's current digital placemaking: <br></br><br></br>
                  <ul>
                    <li>Define the space's identity and create a connection between it and the people who interact with it</li>
                    <li>Make use of the high-tech infrastructure available to push for an innovative, impactful and user-focused experience.</li>
                  </ul> <br></br><br></br>

                  New technologies have opened up the possibilities for artists to express themselves, and created a new need for museums and creative spaces to cater to those new forms of expression. With this, comes the possibility that the audience become an active participant in the art they were conventionally passive viewers of.<br></br><br></br>

                  A study conducted by Ayad and Omayer (2022) on the importance for digital interactive installations in city centers, suggest a few guidelines within which to maximize social interaction and connection between the audience, art and urban space. We considered some of these guidelines during our design process:<br></br><br></br>
                  <ul className="highlight-term">
                    <li>Design for a conceptual theme</li>
                    <li>Design with mixed media</li>
                    <li>Design a collaborative operation</li>
                  </ul> <br></br>
                  </b3>
                </div>
              </div>
            </section>

            <section className="section" id="analysis">
              <div className="section-content">
                <h3>Analysis</h3>
                <div className="sub-section">
                  <b2 className="sub-section-title">
                    Site Analysis
                  </b2>
                  <div className="flex-wrap">
                    <img src={Site} className="half"/>
                    <div className="half">
                      <div>
                        <b4><b>Activities</b></b4><br></br>
                        <b3>Exercising, relaxing, ordering food at the café, browsing exhibitions at the museum
                        </b3><br></br><br></br>
                        <b4><b>Environment</b></b4><br></br>
                        <b3>Busy, noisy, bright, colorful, well-lit at night-time
                        </b3><br></br><br></br>
                        <b4><b>Interactions</b></b4><br></br>
                        <b3>Visitors socializing, children playing together
                        </b3><br></br><br></br>
                        <b4><b>Objects</b></b4><br></br>
                        <b3>Trees, street lights, LED catenary, MAP Mima, billboards, fields, picnic tables
                        </b3><br></br><br></br>
                        <b4><b>Users</b></b4><br></br>
                        <b3>MAP Mima visitors, park goers, employees, tourists
                        </b3><br></br><br></br>
                        <b3 className="highlight-term">
                        Observing the ’Honeypot Effect’: how people interacting with a system passively stimulates others to observe, approach and engage in an interaction.
                        </b3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sub-section">
                  <b2 className="sub-section-title">
                    User profiles
                  </b2>
                  <div className="flex-wrap">
                    <UserProfile name="Tech-friendly youth" age="24-34" pains={["Doesn't identify with conventional art exhibitions", "Won't know about an exhibition unless they have seen it online/on social media"]} needs={["Innovative high-tech implementations", "Excitement and visual aesthetic"]}/>
                    <UserProfile name="Adventurous museumgoer" age="35-45" pains={["Finds some installations difficult to navigate", "Might struggle to keep children stimulated when visiting museums"]} needs={["Intuitive user interactions", "Meaningful experiences"]}/>
                  </div>
                </div>
                <div className="sub-section">
                  <b2 className="sub-section-title">
                    Theme
                  </b2>
                  <div className="limit-width">
                    <b3>
                    As our focus while designing for MAP Mima's installation, we take into consideration their business goals, research trends, and user studies. We found that to best suit both the client and the user's needs, our direction is to create an innovative experience that the user can identify with, and create a connection with the audience, art and space.<br></br><br></br>

                    This led us to a theme which will guide our ideation process:<br></br>
                    </b3>
                    <b2 className="highlight-term"><b>Interconnectivity through collaboration</b>
                    </b2>

                  </div>
                </div>
              </div>
            </section>

            <section className="section" id="concept">
              <div className="section-content">
                <h3>Concept</h3>
                <div className="sub-section">
                  <div className="flex-wrap">
                    <div className="half">
                      <div id="videos">
                        <iframe width="100%" height="100%"
                          src="https://www.youtube.com/embed/QPcoquTRz4A"
                          csp="frame-src youtube.com www.youtube.com http://youtube.com https://youtube.com http://www.youtube.com https://www.youtube.com">
                        </iframe>
                      </div>
                      <b3>
                      A user picks a color and hums into their microphone, and watches the light expand from a pole as time goes by or the louder they hum
                      </b3>
                    </div>
                    <div className="half">
                      <div id="videos">
                        <iframe width="100%"
                          src="https://www.youtube.com/embed/WGUEKOn1O5Q"
                          csp="frame-src youtube.com www.youtube.com http://youtube.com https://youtube.com http://www.youtube.com https://www.youtube.com">
                        </iframe>
                      </div>
                      <b3>
                      Another user's color will expand from another pole and they are encouraged to make colors meet, at which point the interpolation of both their colors twinkles and expands through the line, having successfully formed a connection
                      </b3>
                    </div>
                  </div>
                </div>

                <div className="sub-section">
                  <b2 className="sub-section-title">
                    Fit to theme & brief
                  </b2>
                  <b3 className="limit-width">
                  We have designed our concept to fit our theme of interconnectivity through collaboration, including the humming interaction as a means for people to be drawn to the place (honeypot effect) and another dimension to the product of the collaboration when many users are interacting together. <br></br><br></br>

                  When no one is interacting, the catenary plays a simulation of a connection forming between two random poles at a time, keeping movement and visual interest in the lights and suggesting the theme of connection without there being any user input.  If any one user interacts with the lights and no one else interacts with them for a set period of time, the catenary will default to "no interaction mode" (like a screensaver) until a new user starts interaction, at which point the previous users' interactions will be visible again.
                  </b3>

                </div>

                <div className="sub-section">
                  <b2 className="sub-section-title">
                    Moodboard
                  </b2>

                  <b3>
                    <div className="flex-grid">
                        <img src={Mood1} className="moodboard-item"/>
                        <img src={Mood2} className="moodboard-item"/>
                        <img src={Mood3} className="moodboard-item2"/>
                        <img src={Mood4} className="moodboard-item"/>
                        <img src={Mood5} className="moodboard-item"/>
                    </div>
                    <br></br>
                    Since the catenary will only light up at night and MAP Mima has purplish lights, we will use hues of purple as the main colors for the interface. <br></br>
                    The lights are also low resolution, similar to how the lights are in pixelized-style art, so we opted for using the pixel style effect to emphasize the dot lights.
                  </b3>

                </div>
              </div>
            </section>

            <section className="section" id="sonic-design">
              <div className="section-content">
                <h3>Design</h3>

                <div className="sub-section">
                  <b2 className="sub-section-title">
                    Minimum viable product
                  </b2>
                  <b3>
                  We conducted a MoSCoW analysis to define our minimum viable product (MVP). It is comprised of the bare minimum features required in order to effectively portray our concept comprehensively. Ours is defined by the features outlined in green.
                  </b3>
                  <img src={MVP} className="full-screen"/>
                </div>

                <div className="sub-section">
                  <b2 className="sub-section-title">
                    Low fidelity wireframes
                  </b2>

                  <b3>
                  Since the users are probably going to be interacting with the interface for their first time and they have to learn how to navigate it quickly, we try to make the interface as straightforward and simple as possible. After the user scans the QR code and allow the permission for microphone input, users will immediately see the landing page. At the top will be the name of the project, followed with 2 buttons, Start and About, and at the bottom is an additional information regarding the microphone permission just in case the users did not allow the use of microphone before.
                  </b3>

                  <div className="flex-wrap">
                    <img src={Lofi1} className="wireframe"/>
                    <img src={Lofi2} className="wireframe"/>
                    <img src={Lofi3} className="wireframe"/>
                    <img src={Lofi4} className="wireframe"/>
                  </div>

                  <b3 className="two-column">
                  Users can immediately press on the Start button if they want to just go to interact with the catenary. If the users click on the 'About the project' button, users can read the description about the project itself. If the users click on the 'Start' button, users will go to the next page which is the customization of their catenary settings. Users can pick which pole they want to start with and what color they want their lights to be. <br></br><br></br>

                  After they choose, there is another Next button at the bottom of the page. <br></br>
At the last page of the interface is a bar that move up or down according to the users' voice. On top of the bar is a 'call-to-action' text to let the users know that their voice is going to be the one controlling the catenary lights. A microphone button below the bar is also added to further highlight the use of audio in this interaction.
                  </b3>

                </div>

                <div className="sub-section">
                  <b2 className="sub-section-title">
                    Technical considerations
                  </b2>
                  <b3>
                  Building a working prototype involved creating a mobile app designed from the UI wireframes and a Processing program to display the result of the interactions on the Catenary. A basic diagram of the system architecture from input to output is depicted below:                  
                  </b3>
                  <img src={Archi} className="full-screen"/>
                  <b3>
                  We used VS Code, Processing and Github during the development of the working prototype. All code, history and past and future issues available in our <a id="link" target="_blank" href="https://github.com/ritacoelho/IDEA9102_A2
">public repository on Github.</a>                  </b3>
                </div>
              </div>
            </section>

            <section className="section" id="sonic-evaluation">
              <div className="section-content">
                <h3>Evaluation</h3>

                <div className="sub-section">
                  <b2 className="sub-section-title">
                    Preliminary testing evaluation
                  </b2>
                  <b3>
                  We conducted the testing based on our lo-fi wireframes and a very preliminary product with some features from the MoSCoW analysis. We mainly tested the essence functionality of our mockups, collecting primary user feedback for developing our working prototype. 
                  </b3>
                  <div className="flex-wrap" id="sonic-flex">
                    <div className="half" id="sonic-half">
                      <b2 className="dark-header">
                        User Interface
                      </b2>
                      <div className="base-card">
                        <b3><b>Eyedropper icon is redundant</b></b3>
                        <b3>When users were picking their color, they clicked on the color box instead of the eyedropper icon to open the color preview box. We opt to remove the eyedropper icon altogether and decided to change the color system entirely for easier understanding.
                        </b3>
                      </div>
                      <div className="base-card">
                        <b3><b>”About the project” text overload</b></b3>
                        <b3>In the lo-fi wireframes, we put placeholder paragraphs in the "About the project" page. A lot of users make notes that the text is too long. So, in the real implementation, we will be making sure to keep the description brief and engaging.
                        </b3>
                      </div>
                      <div className="base-card">
                        <b3><b>Pole selection position is unclear</b></b3>
                        <b3>Since our testing is not located in MAP Mima, most users have a difficult time understanding which button represents which pole. We decided to put a landmark or wayfinding location to let the users understand their location better.
                        </b3>
                      </div>
                      <div className="base-card">
                        <b3><b>Visual color feedback while speaking</b></b3>
                        <b3>Users want to be able to see their colors in the audio input page.
                        </b3>
                      </div>
                    </div>
                    <div className="half" id="sonic-half">
                      <b2 className="dark-header">
                        Prototype
                      </b2>
                      <div className="base-card">
                        <b3><b>Loudness/length responsiveness needs review</b></b3>
                        <b3>Adjustment is required to find a balance between how easy or difficult it is to make an audio input
                        </b3>
                      </div>
                      <div className="base-card">
                        <b3><b>Keep the users' color</b></b3>
                        <b3>When celebrating a connection, users want to feel that they participated. We decided to implement all the poles' colors into the celebration so that the users are still able to see their colors. Not only that, users also want to keep seeing their colors even after connecting with other poles.
                        </b3>
                      </div>
                      <div className="base-card">
                        <b3><b>Theme is not clear through placeholder connection effect</b></b3>
                        <b3>Since we're testing using a preliminary prototype, the connection effect may not look really visible. We will take a note of this in the next user testing so the users are able to see the connection effect more clearly.
                        </b3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section">
              <div className="section-content">
                <h3>Prototype</h3>

                <div className="sub-section">
                  <b2 className="sub-section-title">
                    Main refinements
                  </b2>
                  <div className="flex-wrap" id="sonic-flex">
                    <div className="half" id="sonic-half">
                      <div className="base-card-2">
                        <b2><b>Pattern Meaning</b></b2>
                        <b3>We decided against making the pattern a selectable option. Instead, we opted for recreating waves rippling outwards from each pole. This pattern is more meaningful and relevant to our concept, as we are using sound as input, and the waves represent the <b className="highlight-term">expansion of the user's volume as sound waves</b> though the catenary.<br></br><br></br>

                        Furthermore, they also carry contextual relevance to the site which is very connected to the lake where <b className="highlight-term">the same rippling waves can be found in the water</b>.
                        </b3>
                      </div>
                      <div className="base-card-2">
                        <b2><b>Full Catenary Climax</b></b2>
                        <b3>When our users' collective interactions fill up the whole Catenary, a light show including all the users' colors (and MAP Mima branding colors from any non-played nodes) speeds through the Catenary for a climax moment during which users can <b className="highlight-term">enjoy the result of their participation and collaboration</b>.
                        </b3>
                      </div>
                    </div>
                    <div className="half" id="sonic-half">
                      <div className="base-card-2">
                        <b2><b>Connection Between Two Nodes</b></b2>
                        <b3>We decided to use the users' chosen colors and create a rippling effect from the center of the connection. This makes for an interesting visual effect where <b className="highlight-term">users can still identify themselves</b> while perceiving the result of their collaboration with others. After the connection effect, the two colors will interpolate with the use of transparency, while still showing an opaque outline of their original color. <br></br><br></br>

                        We've also decided to give non-played poles a color (matching with MAP Mima's branding) and the same effect results of the connection between a single user and an idle pole - <b className="highlight-term">representing a connection between the user and MAP Mima</b>.
                        </b3>
                      </div>
                      <div className="base-card-2">
                        <b2 style={{"textAlign":"left"}}><b>Sound Affects Expansion & Color Change</b></b2>
                        <b3>Sound-based input allows for an open and playful experience as we use both volume and pitch, to <b className="highlight-term">add an extra dimension</b> of exploration while producing an intentional output.
                        </b3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sub-section">
                  <b2 className="sub-section-title">
                    High fidelity wireframes
                  </b2>

                  <b3 className="two-column">
                    To create the high-fidelity wireframes, we combined the concept to the wireframes that we  created before and implementing the feedback received from our user testing.<br></br>
                    We added a point of reference (MAP Mima's Cube) and changed the pole's position on screen to better reflect their real-life position in relation to most users.
                  </b3>

                  <div className="flex-wrap">
                    <img src={Hifi1} className="wireframe"/>
                    <img src={Hifi2} className="wireframe"/>
                    <img src={Hifi3} className="wireframe"/>
                    <img src={Hifi4} className="wireframe"/>
                  </div>

                  <b3 className="two-column">
                    We decided to change the color-picking UI to a color slider for a more recognizable and simple UI.<br></br><br></br>
                    In the voice input page, we added a line to visually show the user the threshold above which they need to get to expand their color on the lights.
                  </b3>

                </div>

                <div className="sub-section">
                  <b2 className="sub-section-title">
                    High fidelity prototype
                  </b2>
                  <img src={Proto1} className="full-screen"/>
                  <img src={Proto2} className="full-screen"/>
                </div>

                <div className="sub-section">
                  <b2 className="sub-section-title">
                    Pitch video and demo
                  </b2>
                  <div className="full-screen" id="videos">
                    <iframe width="100%" height="100%"
                      src="https://www.youtube.com/embed/wSX3GOIuV6c"
                      csp="frame-src youtube.com www.youtube.com http://youtube.com https://youtube.com http://www.youtube.com https://www.youtube.com">
                    </iframe>
                  </div>
                </div>

              </div>
            </section>

            <section className="section" id="sonic-evaluation">
              <div className="section-content">
                <h3>Evaluation</h3>

                <div className="sub-section">
                  <b2 className="sub-section-title">
                    User testing evaluation
                  </b2>
                  <b3>
                  We planned to run two rounds of user testing, with two groups of users (A & B), from single-user to multiple-users, using the final version working prototype communicating with implemented UI. We observed how the users behaved, their experience and their reactions. The points outlined in green were implemented in the final version. 
                  </b3>
                  <div className="flex-wrap" id="sonic-flex">
                    <div className="half" id="sonic-half">
                      <b2 className="dark-header">
                        User Interface
                      </b2>
                      <div className="base-card" id="card-selected">
                        <b3><b>Color selection only available after user picks pole</b></b3>
                        <b3>Some users tried to proceed to the next screen before picking a pole. This could be prevented by disabling the "next" button until the pole had been chosen, but we decided instead to go for sequential tasks as they make it clear to the user what their affordances are at each given moment and reduces errors.
                        </b3>
                      </div>
                      <div className="base-card">
                        <b3><b>Speech level taking up whole screen</b></b3>
                        <b3>On the screen that depicts the user's volume, users found themselves having to look at their screen too closely while emitting sound to be able to clearly see how their input was affecting the app, and this could take their eyes away from the Catenary. This is something we will consider in the future.
                        </b3>
                      </div>
                      <div className="base-card" id="card-selected">
                        <b3><b>Remove "click to speak" button</b></b3>
                        <b3>Although all users understood they were supposed to emit sound into their phone, they did not always understand that they had to click the microphone button in order for their audio to be captured. This could be fixed with a button icon depicting a "muted" microphone when it was off, but we decided that removing that barrier altogether allowed for a smoother experience, considering the volume level itself gives the user feedback on what their interaction is doing.
                        </b3>
                      </div>
                    </div>
                    <div className="half" id="sonic-half">
                      <b2 className="dark-header">
                        Prototype
                      </b2>
                      <div className="base-card" id="card-selected">
                        <b3><b>Real time color change on pole</b></b3>
                        <b3>Once the user had selected their pole and was browsing through colors, they expected to see the color changing in real-time on the pole they had selected. We decided to implement this as it would further improve visual feedback.
                        </b3>
                      </div>
                      <div className="base-card">
                        <b3><b>Disable poles that have been selected by other users</b></b3>
                        <b3>When doing multiple-user testing, we instructed our users not to pick the same pole as to not overwrite the other's initeractions. This is something that will be implemented in the near future to avoid errors and confusion.
                        </b3>
                      </div>
                      <div className="base-card">
                        <b3><b>Be able to change colors while emitting sound</b></b3>
                        <b3>Some users suggested that to increase the length of the full interaction, the user could be allowed to change their color after having emitted sound waves from one pole with one color. This could be an interesting feature to add in the future.
                        </b3>
                      </div>
                      <div className="base-card">
                        <b3><b>Timeout period</b></b3>
                        <b3>One user was worried about other users locking a pole if they had not been careful to adequately exit the application. This is a valid concern and should be implemented in the near future to ensure many people can enjoy interacting with the installation.
                        </b3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sub-section">
                  <b2 className="sub-section-title">
                    Future developments
                  </b2>
                  <div className="flex-wrap" id="sonic-flex">
                    <div className="half" id="sonic-half">
                      <div className="base-card-3">
                        <b2><b>Implement timeout for idle users</b></b2>
                        <b3>When a user hasn't interacted for a set amount of time, reset their chosen pole to a MAP Mima color. This was tricky to implement for as long as the user's microphone is picking up sound (even just background noise), it sends messages to the catenary, challenging us to find a different way to determine that they are indeed idle.
                        </b3>
                      </div>
                      <div className="base-card-3">
                        <b2><b style={{"textAlign":"left"}}>Disable poles that have been selected by other users</b></b2>
                        <b3>When a user selects a pole, other users should see that it is no longer available for selection. This prevents a user from overriding an existing user's pole, and keeps it clear that a maximum 5 users can play at any given moment. Furthermore, when all 5 poles are taken, our UI should indicate the fact to a new user trying to interact and suggest that they watch while they wait their turn.
                        </b3>
                      </div>
                    </div>
                    <div className="half" id="sonic-half">
                      <div className="base-card-3">
                        <b2><b>Expand volume indication on UI</b></b2>
                        <b3>When users are interacting with the audio capture screen on the UI, they should be looking up at the lights at the effect of their interaction rather than their phone screen. In order to increase the visibility of that indicator without forcing the user to look directly at it, we can use the available screen real estate to increase the size of it so it's visible from the user's peripheral vision. This would need to be mocked up and A/B tested for optimal results.
                        </b3>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              
            </section>


          </div>
      );
    }
}

export default SonicLink;