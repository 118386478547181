import React, { Component} from "react";
//import {hot} from "react-hot-loader";
import "./About.css";

//Material UI Components
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

//Components
import MenuBar from "../MenuBar";

//Assets
import ProfilePic from "../images/ProfilePic.jpg";
import Email from "./images/email.png";
import Figma from "./images/figma.png";
import Github from "./images/github.png";
import LinkedIn from "./images/linkedin.png";

class About extends Component{
  render(){
    return(
        <div className="home">
          <MenuBar/>
          <section className="section">
            <div className="section-content">
              <div className="herosection" id="project">
                <div className="flex-wrap">
                  <img src={ProfilePic} className="half" id="profile"/>
                  <div className="half" id="profile-desc">
                    <h1>
                    Designer and developer
                    </h1>
                    <b2 className="limit-width">
                    The experience I've gained as a UI developer sparked an interest in design that I decided to pursue academically. 
                    <br></br><br></br>
                    Currently, I find that my knowledge of software development is not only useful but essential in designing for human-computer interaction.
                    <br></br><br></br>
                    When I'm not creating, I am usually travelling ✈️, exercising 🏃🏻‍♀️ or listening to music 🎵. 
                    </b2>
                  </div>
                </div>
              </div>

            </div>
          </section>
          <section className="section" id="experience">
            <div className="section-content">
              <h3>Experience</h3>
              <div className="sub-section">
                <div className="flex-wrap" id="experience">
                  <div className="half" id="professional">
                    <div className="exp-title">Professional</div>
                    <div className="jobs">
                      <div className="job">
                        <b2 style={{"textAlign":"left"}}><b>UX & Cloud Development Consultant</b></b2>
                        <div className="company">
                          SAP
                          <b4>Nov 2021 - Jul 2022</b4>
                        </div>
                        <b3 style={{"fontWeight":"400"}}>
                          Developer in a multidisciplinary team responsible for developing full-stack cloud applications in UX projects using SAP UI5, SAP Fiori elements, CDS and Node.js.
                        </b3>
                      </div>
                      <div className="job">
                        <b2 style={{"textAlign":"left"}}><b>Web Developer</b></b2>
                        <div className="company">
                          Vodafone
                          <b4>Nov 2020 - Nov 2021</b4>
                        </div>
                        <b3 style={{"fontWeight":"400"}}>
                          UI/UX design and development of internal web application for Transport Network Operations using Figma, React.js, Node.js and Express.js.
                        </b3>
                      </div>
                      <div className="job">
                        <b2 style={{"textAlign":"left"}}><b>Mobile/Web Developer</b></b2>
                        <div className="company">
                          Build Up Labs - Startup Studio
                          <b4>Summer 2020</b4>
                        </div>
                        <b3 style={{"fontWeight":"400"}}>
                          Contributed to the end-to-end development of a mobile application  (getwiser.net), encompassing market research, wireframing, and front-end development using React.js and Node.js, culminating in successful back-end integration.
                        </b3>
                      </div>
                      <div className="job">
                        <b2 style={{"textAlign":"left"}}><b>User Experience Intern</b></b2>
                        <div className="company">
                          Baseform
                          <b4>Sept 2019 - Dec 2019</b4>
                        </div>
                        <b3 style={{"fontWeight":"400"}}>
                          User interface design and implementation of user activity notifications using Java and typescript.
                        </b3>
                      </div>
                    </div>

                  </div>
                  <div className="half" id="education">
                    <div className="exp-title">Education</div>
                    <div className="jobs">
                      <div className="job">
                        <b2 style={{"textAlign":"left"}}><b>Master of Interaction Design 
  and Electronic Arts</b></b2>
                        <b3 style={{"fontWeight":"400"}}>
                        University of Sydney - School of Architecture, Design and Planning
                        </b3>
                      </div>
                      <div className="job">
                        <b2 style={{"textAlign":"left"}}><b>BSc in Computer Science and Engineering</b></b2>
                        <b3 style={{"fontWeight":"400"}}>
                        University of Lisbon - Instituto Superior Técnico
                        </b3>
                      </div>
                    </div>
                    <br></br>
                    <div className="exp-title">Contact me</div>
                    <div className="jobs">
                      <div className="flex-wrap">
                        <a target="_blank" href="mailto:rita_coelho@icloud.com"><img src={Email} className="icon-img"/></a>
                        <a target="_blank" href="https://www.linkedin.com/in/coelhorita/"><img src={LinkedIn} className="icon-img"/></a>
                        <a target="_blank" href="https://github.com/ritacoelho"><img src={Github} className="icon-img"/></a>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section" id="stack">
            <div className="section-content">
              <h3>Stack</h3>
              <div className="sub-section">
                <div className="flex-wrap" id="stack">
                  <div className="skill-card">
                    <b4 className="skills-title">FRAMEWORKS & LIBRARIES</b4>
                    <div className="skills-columns">
                      <div className="half" id="skill-column">
                        <b4 className="skill">
                          <FiberManualRecordIcon fontSize="8px"  style={{ color: "#BC6C25" }}/>
                          ReactJS
                        </b4>
                        <b4 className="skill">
                          <FiberManualRecordIcon fontSize="8px"  style={{ color: "#BC6C25" }}/>
                          NodeJS
                        </b4>
                        <b4 className="skill">
                          <FiberManualRecordIcon fontSize="8px"  style={{ color: "#BC6C25" }}/>
                          ExpressJS
                        </b4>
                        <b4 className="skill">
                          <FiberManualRecordIcon fontSize="8px"  style={{ color: "#BC6C25" }}/>
                          SAP Fiori Elements
                        </b4>
                        <b4 className="skill">
                          <FiberManualRecordIcon fontSize="8px"  style={{ color: "#BC6C25" }}/>
                          SAP UI5
                        </b4>
                      </div>
                      <div className="half" id="skill-column">
                        <b4 className="skill">
                          p5JS
                        </b4>
                        <b4 className="skill">
                          VueJS
                        </b4>
                        <b4 className="skill">
                          Meteor
                        </b4>
                        <b4 className="skill">
                          Spring
                        </b4>
                        <b4 className="skill">
                          JUnit
                        </b4>
                      </div>
                    </div>
                  </div>
                  <div className="skill-card">
                    <b4 className="skills-title">Other tools</b4>
                    <div className="skills-columns">
                      <div className="half" id="skill-column">
                        <b4 className="skill">
                          Figma
                        </b4>
                        <b4 className="skill">
                          Adobe InDesign
                        </b4>
                        <b4 className="skill">
                          Adobe Photoshop
                        </b4>
                        <b4 className="skill">
                          Adobe After Effects
                        </b4>
                        <b4 className="skill">
                          Adobe Premiere Pro
                        </b4>
                      </div>
                      <div className="half" id="skill-column">
                        <b4 className="skill">
                          Rhino 3D
                        </b4>
                        <b4 className="skill">
                          Grasshopper
                        </b4>
                        <b4 className="skill">
                          Unity
                        </b4>
                        <b4 className="skill">
                          Blender
                        </b4>
                      </div>
                    </div>
                  </div>
                  <div className="skill-card">
                    <b4 className="skills-title">Languages</b4>
                    <b5 className="skills-title" style={{"fontWeight":"600","color":"#403D39"}}>Programming</b5>
                    <div className="skills-columns">
                      <div className="half" id="skill-column">
                        <b4 className="skill">
                          <FiberManualRecordIcon fontSize="8px"  style={{ color: "#BC6C25" }}/>
                          Javascript
                        </b4>
                        <b4 className="skill">
                          <FiberManualRecordIcon fontSize="8px"  style={{ color: "#BC6C25" }}/>
                          HTML
                        </b4>
                        <b4 className="skill">
                          <FiberManualRecordIcon fontSize="8px"  style={{ color: "#BC6C25" }}/>
                          CSS
                        </b4>
                        <b4 className="skill">
                          <FiberManualRecordIcon fontSize="8px"  style={{ color: "#BC6C25" }}/>
                          Java
                        </b4>
                      </div>
                      <div className="half" id="skill-column">
                        <b4 className="skill">
                          Python
                        </b4>
                        <b4 className="skill">
                          C
                        </b4>
                        <b4 className="skill">
                          MySQL
                        </b4>
                        <b4 className="skill">
                          PHP
                        </b4>
                      </div>
                    </div>
                    <b5 className="skills-title" style={{"fontWeight":"600","color":"#403D39"}}>Spoken</b5>
                    <div className="skills-columns">
                      <div className="half" id="skill-column">
                        <b4 className="skill">
                          <b5 className="highlight-term">C2</b5>
                          English
                        </b4>
                        <b4 className="skill">
                          <b5 className="highlight-term">C2</b5>
                          Portuguese
                        </b4>
                      </div>
                      <div className="half" id="skill-column">
                        <b4 className="skill">
                          <b5 className="highlight-term">C1</b5>
                          French
                        </b4>
                        <b4 className="skill">
                          <b5 className="highlight-term">B1</b5>
                          Spanish
                        </b4>
                      </div>
                    </div>
                  </div>
                  <div className="skill-card">
                    <b4 className="skills-title">Interests</b4>
                    <div className="flex-wrap" id="interests">
                      <b4 className="interest">UI/UX</b4>
                      <b4 className="interest">Design</b4>
                      <b4 className="interest">Games</b4>
                      <b4 className="interest">Travel</b4>
                      <b4 className="interest">Makeup</b4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    );
  }
}

export default About;