import React, { Component } from "react";
//import {hot} from "react-hot-loader";
import "./App.css";

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from "./pages/Home";
import Maintenance from "./pages/Maintenance";
import LastMileDelivery from "./pages/LastMileDelivery";
import SonicLink from "./pages/SonicLink";
import AtlassianPilot from "./pages/AtlassianPilot";
import About from "./pages/About";
import Contact from "./pages/Contact";

class App extends Component{
  render() {
    
    let homePage = process.env.REACT_APP_MAINTENANCE === true ? <Maintenance/> : <Home/>;
  
    console.log(process.env.REACT_APP_MAINTENANCE);
    return (
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={homePage}></Route>
          <Route exact path='/about' element={<About/>}></Route>
          {/* <Route exact path='/contact' element={<Contact/>}></Route> */}
          <Route exact path='/atlassian-pilot' element={<AtlassianPilot/>}></Route>
          <Route exact path='/sonic-link' element={<SonicLink/>}></Route>
          <Route exact path='/last-mile-delivery' element={<LastMileDelivery/>}></Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;