import React, { Component} from "react";
//import {hot} from "react-hot-loader";
import "./UserProfile.css";

function UserProfile(props){

    //name
    //age range
    //pains
    //needs

    let painsDivs = [];
    let needsDivs = [];

    props.pains.forEach(pain => {
        painsDivs.push(<li key={pain}><b4>{pain}</b4></li>)
      });

    props.needs.forEach(need => {
        needsDivs.push(<li key={need}><b4>{need}</b4></li>)
      });

  return(
    <div className="profile-container">
        <b2 className="profile-name">
            {props.name}
        </b2>
        <b4 id="terracota-highlight"><b>Age range</b></b4>
        <b3 className="age">
            {props.age}
        </b3>
        <b4 id="terracota-highlight"><b>Pain points</b></b4>
        <ul className="points">
            {painsDivs}
        </ul>
        <b4 id="terracota-highlight"><b>Needs</b></b4>
        <ul className="points">
            {needsDivs}
        </ul>
    </div>
  );
}

export default UserProfile;