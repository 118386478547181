import React, { Component, useState } from "react";
//import {hot} from "react-hot-loader";
import "./LastMileDelivery.css";

//MUI Components
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

//Components
import MenuBar from "../MenuBar";
import DesignProcessStep from "../components/DesignProcessStep";
import UserProfile from "../components/UserProfile";

//Assets
import Target from "../images/LastMileDelivery/target-solution.png";
import IpadScreens from "../images/LastMileDelivery/ipad-screens.png";
import MenuDelivering from "../images/LastMileDelivery/menu-delivering.png";
import Track from "../images/LastMileDelivery/track.png";
import Details from "../images/LastMileDelivery/details.png";
import Receive from "../images/LastMileDelivery/qr-code.png";
import Confirmation from "../images/LastMileDelivery/confirmation.png";
import Delivered from "../images/LastMileDelivery/delivered.png";
import Wireflow from "../images/LastMileDelivery/wireflow.png";



class LastMileDelivery extends Component{
    render(){
      return(
          <div className="home">
            <MenuBar/>

            <section className="section">
              <div className="section-content">
                <div className="herosection" id="project">
                  <div className="half">
                    <h1>
                    Autonomous Last-Mile Delivery for Online Shopping Platforms
                    </h1>
                    <b2>
                    As part of a group project for university, I was challenged to design a business-aware, logistically coherent  solution for the interaction between the customer and the autonomous delivery device. 
                    </b2>
                  </div>
                  <img src={IpadScreens} className="half"/>
                </div>
              </div>
            </section>

            <section className="section" id="design-process">
              <div className="section-content">
                <h3>Design Process</h3>
                <div className="design-process-timeline">
                    <DesignProcessStep name="Research" step="1" methods={["background", "competitor analysis", "online ethnography"]}/>
                    <DesignProcessStep name="Analysis" step="2" methods={["user profiles", "how might we", "jobs-to-be-done"]}/>
                    <DesignProcessStep name="Ideation" step="3" methods={["brainstorming", "sketching", "define features"]}/>
                    <DesignProcessStep name="Prototyping" step="4" methods={["wireflows", "lo-fi wireframes", "hi-fi prototype"]}/>
                    <DesignProcessStep name="Testing" step="5" methods={["think aloud", "heuristic evaluation", "task analysis"]}/>
                </div>
              </div>
            </section>

            <section className="section" id="research">
              <div className="section-content">
                <h3>Research</h3>
                <div className="sub-section">
                  <b2 className="sub-section-title">
                    BACKGROUND
                  </b2>
                  <div className="highlight">
                    <b2 className="highlight-term">
                      "Last-Mile Delivery"
                    </b2>
                    <b3 className="two-column">
                    represents a significant part of the total cost of parcel transportation (Macioszek, 2017), which is why it is a relevant logistic for which to design a solution. One existing solution is pick-up lockers, where shipping companies deliver many parcels to the same collection point, and the recipients travel there to claim them (Orenstein et al., 2019). <br></br><br></br>

                    Compared to having a driver travel to each address and deliver the package, collection points reduce labour and transportation costs, such as fuel consumption, making it a more sustainable option. <br></br>

                    This cost is generally reflected in the consumer's shopping, where they have to pick between shipping to their home for a fee versus free shipping to a collection point, raising the question of whether the convenience is worth the cost to the consumer. <br></br><br></br>

                    Another cost-intensive aspect of home deliveries is delivery failures - where resources are allocated to bring the parcel to the recipient but they are not there to claim it, or other circumstances impede the package from arriving at the destination (OptimoRoute, 2022).
                    </b3>
                  </div>
                </div>
              </div>
            </section>

            <section className="section" id="analysis">
              <div className="section-content">
                <h3>Analysis</h3>
                <div className="sub-section">
                  <b2 className="sub-section-title">
                    User profiles
                  </b2>
                  <div className="flex-wrap">
                    <UserProfile name="Budget Students" age="18-25" pains={["Packages stolen when left at building entrance"]} needs={["Timely deliveries", "Personal delivery confirmation"]}/>
                    <UserProfile name="Working Adults" age="22-39" pains={["Is not home to accept deliveries", "Orders many things at once", "Want to return what they didn't like"]} needs={["Timely deliveries", "Flexible delivery alternatives", "Flexible return options"]}/>
                    <UserProfile name="Stay-at-home Adults" age="40-50" pains={["Not as well versed with technology", "Needs insurance that orders arrive undamaged"]} needs={["Good customer support", "Direct and safe deliveries"]}/>

                  </div>
                </div>
              </div>
            </section>

            <section className="section" id="problem">
              <div className="section-content">
                <h3>Problem Context</h3>
                <div className="flex-wrap">
                  <b2 className="half">
                  The design should be centered around the user's experience while keeping the online shopping platform's interests in focus as well. We found direction in the user's pains and needs gathered from user research and analysis, while being aware of the management requirements of the proposed design solution. 
                  </b2>
                  <img src={Target} className="half"/>
                </div>
              </div>
            </section>

            <section className="section" id="solution">
              <div className="section-content">
                <h3>Design Solution</h3>
                <div className="sub-section">
                  <b2 className="sub-section-desc">
                  For our proposed solution, we have designed an interface to be integrated into the online store's application addressing three main aspects of the customer's interaction with the autonomous delivery robot.
                  </b2>
                  <div className="flex-wrap">
                    <div className="base-card">
                      <h4>Schedule Delivery Times</h4>
                      <b3>Allows user the flexibility to pick a time-frame during which to have the order delivered, some intervals being free and others paid. This gives the customer the control while helping Nozama reduce costs by coordinating the free intervals on times of greater availability.</b3>
                    </div>
                    <div className="base-card">
                      <h4>Track and Receive an Order</h4>
                      <b3>Ensures the parcel is delivered safely and to the right person, while helping them through the interaction with the delivery robot. This makes for a smooth experience for the user and minimizes the time the robot spends on delivering any given order.</b3>
                    </div>
                    <div className="base-card">
                      <h4>Schedule Return Pick-up</h4>
                      <b3>Allows the customer to schedule a robot to pick up their returns. Although we would not want to encourage returns, it is important for Nozama's customer satisfaction and brand loyalty for customers to have access to hassle-free returns. This might also give them the comfort to order more often.</b3>
                    </div>
                  </div>
                </div>  
              </div>
            </section>

            <section className="section" id="prototype">
              <div className="section-content">
                <h3>Prototype</h3>
                <div className="flex-wrap">
                  <b2 className="half">
                  As a group of three, we were each assigned a feature from above to design and prototype on a different platform, so we created a design system so that our designs were coherent with each other. I was responsible for the tracking and delivering orders interactions on iPad.
                  </b2>
                  <img src={IpadScreens} className="half"/>
                </div>

                <div className="sub-section">
                  <div className="flex-wrap">
                    <div className="screen-highlight">
                      <h4>Delivering Orders and Menu</h4>
                      <img src={MenuDelivering}/>
                    </div>
                    <div className="screen-highlight">
                      <h4>Order Tracking</h4>
                      <img src={Track}/>
                    </div>
                  </div>
                  <h4 className="annotation">
                    <p id="terracota-highlight">Visual framework</p> maintained throughout all screens
                  </h4>
                </div>

                <div className="sub-section">
                  <div className="flex-wrap">
                    <div className="screen-highlight">
                      <h4>Order Details</h4>
                      <img src={Details}/>
                    </div>
                    <div className="screen-highlight">
                      <h4>Receive Order</h4>
                      <img src={Receive}/>
                    </div>
                  </div>
                  <h4 className="annotation">
                    <p id="terracota-highlight">Minimal aesthetic</p> opens up the space on screen
                  </h4>
                </div>

                <div className="sub-section">
                  <div className="flex-wrap">
                    <div className="screen-highlight">
                      <h4>Delivery Confirmation</h4>
                      <img src={Confirmation}/>
                    </div>
                    <div className="screen-highlight">
                      <h4>Delivered Orders</h4>
                      <img src={Delivered}/>
                    </div>
                  </div>
                  <h4 className="annotation">
                    <p id="terracota-highlight">Appropriate spacing and sizing</p> for device
                  </h4>
                </div>
              </div>
            </section>

            <section className="section" id="wireflow">
              <div className="section-content">
                <h3>Wireflow</h3>
                <div className="flex-wrap">
                  <img src={Wireflow} className="full-screen"/>
                </div>
              </div>
            </section>

            <section className="section" id="evaluation">
              <div className="section-content">
                <h3>Heuristic Evaluation</h3>
                <div className="limit-width">
                  <b2>
                  Three heuristic evaluations were conducted using a Figma prototype and Google Forms. The lowest and highest scored heuristics provided insight on what aspects of the interface to focus on improving for the final prototype.                
                  </b2>
                  <div className="heuristics">
                    <div className="h-column">
                      <div className="feedback">
                        <ArrowCircleDownIcon style={{ color: "#C92E2E" }}/>
                        <b3><b>Visibility of system status</b></b3>
                      </div>
                      <div className="feedback">
                        <ArrowCircleDownIcon style={{ color: "#C92E2E" }}/>
                        <b3><b>Match between system and real world</b></b3>
                      </div>
                      <div className="feedback">
                        <ArrowCircleDownIcon style={{ color: "#C92E2E" }}/>
                        <b3><b>Consistency and standards</b></b3>
                      </div>
                    </div>
                    <div className="h-column">
                      <div className="feedback">
                        <ArrowCircleUpIcon style={{ color: "#51AD30" }}/>
                        <b3><b>Recognition rather than recall</b></b3>
                      </div>
                      <div className="feedback">
                        <ArrowCircleUpIcon style={{ color: "#51AD30" }}/>
                        <b3><b>Flexibility and efficiency of use</b></b3>
                      </div>
                      <div className="feedback">
                        <ArrowCircleUpIcon style={{ color: "#51AD30" }}/>
                        <b3><b>Aesthetic and minimalist design</b></b3>
                      </div>
                    </div>
                  </div>
                  <b3>
                  With this feedback, I focused on keeping the information consistent across all screens, use language that the user will understand, and ensure the right information is standing out at the right time.
                  </b3>
                </div>
              </div>
            </section>

            <section className="section" id="interactive-prototype">
              <div className="section-content">
                <h3>Interactive Prototype</h3>
                <div className="limit-width">
                  <b2>
                  The culmination of the entire design process is the interactive high-fidelity prototype created on Figma, where a user can view the delivering orders, order details, track and receive an order, and more...                
                  </b2>
                  <Stack spacing={2} direction="row">
                    <Button variant="outlined" target="_blank" href="https://www.figma.com/file/YMpjHWqVbLTxXt59qb9TFU?type=design&node-id=3-55&mode=design&fuid=1159326766336704825" style={{
                        color: "black",
                        borderColor: "black",
                        padding: "12px"
                    }}><b4>Go to Figma file</b4></Button>
                    <Button variant="contained" target="_blank" href="https://www.figma.com/proto/YMpjHWqVbLTxXt59qb9TFU/LastMile_A3_asup6290_mbai7626_shar5741?page-id=3%3A55&node-id=405%3A9276&viewport=-239%2C485%2C0.1&scaling=scale-down&starting-point-node-id=405%3A9276&fuid=1159326766336704825" style={{
                        color: "white",
                        backgroundColor: "black"
                    }}><b4>Try out the interactive prototype</b4></Button>
                  </Stack>
                </div>
              </div>
            </section>


          </div>
      );
    }
}


export default LastMileDelivery;