import React, { Component} from "react";
//import {hot} from "react-hot-loader";
import "./MenuBar.css";

import {
  Link
} from 'react-router-dom';

class MenuBar extends Component{
  render(){
    let currentPage;

    if (window.location.href.indexOf("about") != -1) {
      currentPage = "about";
    } else if (window.location.href.indexOf("contact") != -1) {
      currentPage = "contact";
    } else {
      currentPage = "home";
    }


    return(
      <div className="menu-bar-container">
        <div className="menu-bar">
          <Link to="/">
            <div className="home-button">RITA COELHO</div>
          </Link>
          
          <div className="menu-buttons">
            <Link to="/">
              <div className="menu-button" id={currentPage === "home" ? "selected" : ""}>WORK</div>
            </Link>
            <Link to="/about">
              <div className="menu-button" id={currentPage === "about" ? "selected" : ""}>ABOUT</div>
            </Link>
            {/* <Link to="/contact">
              <div className="menu-button" id={currentPage === "contact" ? "selected" : ""}>CONTACT</div>
            </Link> */}
        </div>
        
        </div>
      </div>
    );
  }
}

export default MenuBar;