import React, { Component} from "react";
//import {hot} from "react-hot-loader";
import "./Home.css";


import MenuBar from "../MenuBar";
import Portfolio from "../work/Portfolio";


class Home extends Component{
  render(){
    return(
        <div className="home">
          <MenuBar/>
          <section className="section">
            <div className="section-content">
              <div className="herosection">
                <div>
                  I'm Rita, a <b>software developer</b> and <b>ui designer</b> <br></br>
                  from sunny Lisbon, Portugal ☀️
                </div>
                <div>
                  I’m passionate about <b>intuitive design</b> that is both efficient and aesthetically pleasing while <b>optimised for implementation</b>
                </div>
              </div>
            </div>
          </section>
          <section id="work" className="section">
            <div className="section-content">
              <Portfolio/>
            </div>
          </section>
        </div>
    );
  }
}

export default Home;