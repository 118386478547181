import React, { Component, useState } from "react";
//import {hot} from "react-hot-loader";
import "./AtlassianPilot.css";

//MUI Components
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

//Components
import MenuBar from "../MenuBar";
import DesignProcessStep from "../components/DesignProcessStep";
import UserProfile from "../components/UserProfile";

//Assets
import Screens from "../images/Pilot/mockup.png";
import Target from "../images/Pilot/target-solution.png";
import Archi from "../images/Pilot/archi.png";
import A1 from "../images/Pilot/analytics1.png";
import A2 from "../images/Pilot/analytics2.png";
import A3 from "../images/Pilot/analytics3.png";
import P1 from "../images/Pilot/new-project1.png";
import P2 from "../images/Pilot/new-project2.png";
import P3 from "../images/Pilot/new-project3.png";


class AtlassianPilot extends Component{
    render(){
      return(
          <div className="home">
            <MenuBar/>

            <section className="section">
              <div className="section-content">
                <div className="herosection" id="project">
                  <div className="half">
                    <h1>
                    Pilot - Atlassian Design Project
                    </h1>
                    <b2>
                    As part of the capstone project for my master's degree, I had the chance to draw together my acquired knowledge to develop a design proposal for an industry-focused brief.
                    </b2>
                  </div>
                  <img src={Screens} className="half"/>
                </div>
              </div>
            </section>

            <section className="section" id="design-process">
              <div className="section-content">
                <h3>Design Process</h3>
                <div className="design-process-timeline">
                    <DesignProcessStep name="Research" step="1" methods={["debriefing", "literature review", "competitor analysis"]}/>
                    <DesignProcessStep name="User Studies" step="2" methods={["questionnaires", "interviews", "online ethnography"]}/>
                    <DesignProcessStep name="Analysis" step="3" methods={["user profiles", "how might we", "design opportunities"]}/>
                    <DesignProcessStep name="Ideation" step="4" methods={["crazy eight", "brainstorming", "decsion matrix"]}/>
                    <DesignProcessStep name="Prototyping" step="5" methods={["sketching", "lo-fi wireframes", "hi-fi prototype"]}/>
                    <DesignProcessStep name="Testing" step="6" methods={["think aloud", "heuristic evaluation", "task analysis"]}/>
                </div>
              </div>
            </section>

            <section className="section" id="research">
              <div className="section-content">
                <h3>Research</h3>
                <div className="sub-section">
                  <b2 className="sub-section-title">
                    Debriefing
                  </b2>
                  <div className="flex-wrap">
                    <b2 className="half">
                    <b1 style={{"textAlign":"left"}}><b>Younger generations are shaping the way the workforce evolves.</b></b1> <br></br>
                    In order to stay ahead of the industry as Atlassian has been doing for years, it is imperative to expand and adapt to young people's needs before they enter the job market.
                    <br></br><br></br>
                    Atlassian is looking to develop a new product to add to their suite, which will appeal to young users before they enter the workplace and which they can take with them into their first jobs.
                    </b2>
                    <img src={Target} className="half"/>
                  </div>
                </div>
                <div className="sub-section">
                  <b2 className="sub-section-title">
                    How Might We
                  </b2>
                  <div className="flex-wrap">
                    <div className="base-card-2">
                      <b2><b>Factors for academic success</b></b2>
                      <ul>
                        <li><b3>Clear objectives & goals</b3></li>
                        <li><b3>Interactive, dynamic groups</b3></li>
                        <li><b3>Timely feedback</b3></li>
                        <li><b3>Frequent, synchronous communication</b3></li>
                        <li><b3>Peer & self-assessment</b3></li>
                      </ul>
                    </div>

                    <div className="base-card-2">
                      <b2><b>Current design trends</b></b2>
                      <ul>
                        <li><b3>Focus on visual design</b3></li>
                        <li><b3>User-friendly</b3></li>
                        <li><b3>Participation/interaction with peers</b3></li>
                        <li><b3>Customization</b3></li>
                        <li><b3>Authenticity</b3></li>
                      </ul>
                    </div>

                    <div className="base-card-2">
                      <b2><b>Use of artificial intelligence</b></b2>
                      <ul>
                        <li><b3>Improved instructional quality</b3></li>
                        <li><b3>Improved learning experience through use of customisation and personalisation</b3></li>
                        <li><b3>More and more software companies are unveiling AI tools for businesses (Microsoft, Google, Github)</b3></li>
                        <li><b3>Improved working efficiency</b3></li>
                      </ul>
                    </div>
                    
                  </div>
                </div>
              </div>
            </section>

            <section className="section" id="analysis">
              <div className="section-content">
                <h3>Analysis</h3>
                <div className="sub-section">
                  <b2 className="sub-section-title">
                    Quantitative insights
                  </b2>
                  <div className="flex-wrap">
                    <div className="stats">
                      <b1>95%</b1>
                      <b3 style={{"textAlign":"left", "fontWeight":"400"}}>of participants use digital tools for workflow tasks</b3>
                    </div>
                    <div className="stats">
                      <b1>86%</b1>
                      <b3 style={{"textAlign":"left", "fontWeight":"400"}}>of participants can further improve their time and task management</b3>
                    </div>
                    <div className="stats">
                      <b1>80%</b1>
                      <b3 style={{"textAlign":"left", "fontWeight":"400"}}>of participants find it hard to adhere to new tools</b3>
                    </div>
                    
                  </div>
                </div>
                <div className="sub-section">
                  <b2 className="sub-section-title">
                    User profiles
                  </b2>
                  <div className="flex-wrap">
                    <UserProfile name="Students" age="21-25" pains={["Lack direction about professional workflow", "Hard time balancing priorities", "Uncooperative group work"]} needs={["Access to resources", "Be productive with others", "Manage time efficiently"]}/>
                    <UserProfile name="New Employees" age="26-30" pains={["Hard to learn new tools", "Tasks taking longer than expected", "Not used to working in large teams"]} needs={["Communication with coworkers", "Manage time to complete tasks between meetings", "Tasks to be clearly defined"]}/>
                  </div>
                </div>
                <div className="sub-section">
                  <b2 className="sub-section-title">
                    How might we
                  </b2>
                  <div className="flex-wrap">
                    <div className="base-card-2">
                      <b3 style={{"textAlign":"left", "fontWeight":"400"}}>Adapt the user's experience to their personal needs?</b3>
                    </div>
                    <div className="base-card-2">
                      <b3 style={{"textAlign":"left", "fontWeight":"400"}}>Help users more accurately allocate time to complete their tasks?</b3>
                    </div>
                    <div className="base-card-2">
                      <b3 style={{"textAlign":"left", "fontWeight":"400"}}>Keep users engaged and coming back to our tool?</b3>
                    </div>
                    
                  </div>
                </div>
              </div>
            </section>

            <section className="section" id="pilot-ideation">
              <div className="section-content">
                <h3>Ideation</h3>
                <div className="sub-section">
                  <b2 className="sub-section-title">
                    Initial concepts
                  </b2>
                  <b2 className="sub-section-desc">
                  After doing a Crazy 8 ideation exercise and brainstorming to further develop and categorize our ideas, we narrowed them down into three  most viable concepts:
                  </b2>
                  <div className="flex-wrap">
                    <div className="base-card-4" id="card-4-selected">
                      <b3 style={{"fontWeight":"400"}}>Project management tool with AI assistance for task estimation, scheduling meetings, team progress analytics + calls to action to ensure team engagement</b3>
                    </div>
                    <div className="base-card-4">
                      <b3 style={{"fontWeight":"400"}}>Time management tool where user can switch between personal, academic, and professional responsibilities.</b3>
                    </div>
                    <div className="base-card-4">
                      <b3 style={{"fontWeight":"400"}}>Whiteboard/text editor for real-time collaboration with in-app access to chat, video/voice call</b3>
                    </div>
                  </div>
                  <b2 className="sub-section-desc">
                  We then used a decision matrix, using criteria such as <b>alignment to user needs</b>, <b>adaptability to context</b> and <b>integration with workflow</b>, to pick which concept to take into development.
                  </b2>
                </div>  
              </div>
            </section>

            <section className="section">
              <div className="section-content">
                <h3>Design</h3>
                <div className="sub-section">
                  <b2 className="sub-section-title">
                    Information architecture
                  </b2>
                  <b2 className="sub-section-desc">
                  Below is a basic diagram depicting the pages and features we developed for our concept.
                  </b2>
                  <img src={Archi} className="full-screen"/>
                </div>

                <div className="sub-section">
                  <b2 className="sub-section-title">
                    Iterations and user feedback
                  </b2>
                  <b2 className="sub-section-desc">
                  As a group, we developed a prototype for all of the features depicted in our information architecture, below is the detailed iterative process for the pages I was responsible for designing and prototyping:
                  </b2>
                  
                  <div className="page-details">
                    <b2 style={{"textAlign":"left"}}><b>Analytics page</b> <br></br>View team and individual progress at a glance</b2>
                    <div className="flex-wrap">
                      <div className="half" id="iterations">
                        <div className="iterations" id="pre-final">
                          <b5><b>Hi-Fi 1</b></b5>
                          <img src={A1}/>
                          <b5 className="highlight-term">Would like to compare personal performance to others</b5>
                        </div>
                        <div className="iterations" id="pre-final">
                          <b5><b>Hi-Fi 2</b></b5>
                          <img src={A2}/>
                          <b5 className="highlight-term">Potential privacy issues with access to others’ performance</b5>
                        </div>
                      </div>
                      <div className="half" id="final">
                      <div className="iterations">
                          <b5><b>Final Prototype</b></b5>
                            <img src={A3}/>
                          </div>
                      </div>
                    </div>

                  </div>

                  <div className="page-details">
                    <b2 style={{"textAlign":"left"}}><b>New Project dialog</b> <br></br>Create a new project with the help of AI and further customization</b2>
                    <div className="flex-wrap">
                      <div className="half" id="iterations">
                        <div className="iterations" id="pre-final">
                          <b5><b>Hi-Fi 1</b></b5>
                          <img src={P1}/>
                          <b5 className="highlight-term">Maximal design and navigation issues</b5>
                        </div>
                        <div className="iterations" id="pre-final">
                          <b5><b>Hi-Fi 2</b></b5>
                          <img src={P2}/>
                          <b5 className="highlight-term">Lacks freedom to edit</b5>
                        </div>
                      </div>
                      <div className="half" id="final">
                      <div className="iterations">
                          <b5><b>Final Prototype</b></b5>
                            <img src={P3}/>
                          </div>
                      </div>
                    </div>

                  </div>

                </div>

                <div className="sub-section">
                  <b2 className="sub-section-title">
                    User testing results
                  </b2>
                  <b2 className="sub-section-desc">
                  Each prompt was presented as a scale from 1-5, the following are the average results from the last round of user testing.                  
                  </b2>
                  <div className="flex-wrap">
                    <div className="stats">
                      <b1>86%</b1>
                      <b3 style={{"textAlign":"left", "fontWeight":"400"}}>Successfully responded to the design concept</b3>
                    </div>
                    <div className="stats">
                      <b1>82%</b1>
                      <b3 style={{"textAlign":"left", "fontWeight":"400"}}>Adaptability to studies, workplace, and personal tasks</b3>
                    </div>
                    <div className="stats">
                      <b1>82%</b1>
                      <b3 style={{"textAlign":"left", "fontWeight":"400"}}>Overall satisfaction with the current prototype</b3>
                    </div>
                    
                  </div>
                </div>
                
              </div>
            </section>

            <section className="section" id="pilot-summary">
              <div className="section-content">
                <h3>Summary</h3>
                <div className="sub-section">
                  <b2 className="sub-section-title">
                    Value proposition
                  </b2>
                  <b2 className="sub-section-desc">
                  After the development of our concept's high-fidelity prototype, we reflect on how it brings value to its users.            
                  </b2>
                  <div className="flex-wrap">
                    <div className="base-card-4">
                      <b3 style={{"textAlign":"left", "fontWeight":"400"}}>Manage responsibilities across available time and team members</b3>
                    </div>
                    <div className="base-card-4">
                      <b3 style={{"textAlign":"left", "fontWeight":"400"}}>Break down project requirements into tangible goals</b3>
                    </div>
                    <div className="base-card-4">
                      <b3 style={{"textAlign":"left", "fontWeight":"400"}}>Reduce time figuring our logistics</b3>
                    </div>
                  </div>
                  <b2 className="sub-section-desc">
                  We also refer back to Atlassian's brief and see how our final concept fits into their goals and business interests.             
                  </b2>
                  <div className="flex-wrap">
                    <div className="base-card-2">
                      <b3 style={{"textAlign":"left", "fontWeight":"400"}}>Designed to get students used to a workflow similar to that of a professional</b3>
                    </div>
                    <div className="base-card-2">
                      <b3 style={{"textAlign":"left", "fontWeight":"400"}}>Coherent with Atlassian's suite, making it easier for its users to adopt its other products</b3>
                    </div>
                    <div className="base-card-2">
                      <b3 style={{"textAlign":"left", "fontWeight":"400"}}>Use of AI, as is expected in leading tech, is leveraged to achieve the user's goals</b3>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section" id="interactive-prototype">
              <div className="section-content">
                <h3>Prototype & Demo</h3>
                <div className="flex-wrap">
                  <div className="half">
                    <b2>
                    Watch our demo, try out the prototype or view the comprehensive work done to reach our final prototype.             
                    </b2>
                    <Stack spacing={2} direction="row">
                      <Button variant="outlined" target="_blank" href="https://www.figma.com/file/SPRUzxq3I582g1UO33joed/IDEA9301?type=design&node-id=20-2&mode=design&t=hpmil97VrhE5cU3F-0" style={{
                          color: "black",
                          borderColor: "black",
                          padding: "12px"
                      }}><b4>Go to Figma file</b4></Button>
                      <Button variant="contained" target="_blank" href="https://www.figma.com/proto/WM6bij5ImFDv9YC7r7k8zW/DESIGN-STUDIO-FINAL-PROTOTYPE?type=design&node-id=0-1&t=yNgEjk700LACVZdx-0&scaling=min-zoom&starting-point-node-id=1%3A24386&show-proto-sidebar=1" style={{
                          color: "white",
                          backgroundColor: "black"
                      }}><b4>Try out the interactive prototype</b4></Button>
                    </Stack>
                  </div>

                  <div className="half">
                    <div id="videos">
                      <iframe width="100%" height="100%"
                        src="https://www.youtube.com/embed/e6zObXljml0" csp="frame-src youtube.com www.youtube.com http://youtube.com https://youtube.com http://www.youtube.com https://www.youtube.com">
                        
                      </iframe>
                    </div>
                  </div>

                </div>
              </div>
            </section>
          </div>
      );
    }
}


export default AtlassianPilot;