import React, { Component} from "react";
//import {hot} from "react-hot-loader";
import "./DesignProcessStep.css";

function DesignProcessStep(props){

    //name
    //number
    //methods

    let methodDivs = [];

    props.methods.forEach(method => {
        methodDivs.push(<b4 key={method}>{method}</b4>)
      });

  return(
    <div className="step-container">
        <h4 className="step-name">
            {props.name}
        </h4>
        <h3 className="step">
            {props.step}
        </h3>
        <div className="methods">
            {methodDivs}
        </div>
    </div>
  );
}

export default DesignProcessStep;