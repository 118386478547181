import React, { Component } from "react";
//import {hot} from "react-hot-loader";
import "./Thumbnail.css";

import {
    Link
} from 'react-router-dom';


function Thumbnail(props) {

    return(
        <Link to={props.url} className="thumbnail-container">
            <div className="thumbnail">
                <img src={props.img} className="thumbnail-image"/>
                <div className="thumbnail-hover">
                        {props.name}
                </div>
            </div>
        </Link>
    )
}

export default Thumbnail;